<template>
  <div>
    <breadcrumbs :items="breadcrumbs"/>

    <div class="ml-6">
      <div>
        <v-row class="mt-1">
          <v-col cols="11">
            <v-btn
                :disabled="flagDownloadingProducts || flagLoadingProducts || products.length === 0"
                @click="exportProducts()"
                small
                class="mb-5 info"
            >
              <v-icon small class="mr-2">mdi-file-excel</v-icon>
              Export
            </v-btn>

            <v-data-table
                dense
                :headers="productsHeaders"
                :items="products"
                item-key="name"
                class="elevation-1"
                :loading="flagLoadingProducts"
            >
              <template v-slot:loading>
                <div class="pb-5">
                  <v-progress-circular
                      :rotate="360"
                      :size="30"
                      :width="1"
                      color="success"
                      indeterminate
                      class="mt-5"
                  >
                  </v-progress-circular>
                </div>
              </template>
              <template v-slot:item.actions="{ item, index }">
                <v-btn color="success" x-small @click="editProduct(index)">
                  <v-icon small class="mr-2">mdi-check</v-icon>
                  Fix
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-overlay :value="flagDownloadingProducts">
      <v-progress-circular
          indeterminate
          size="64"
          :width="1"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default ({
  name: "IncompleteProducts",

  data: () => ({
    operationResultMessage: "",
    operationResultColor: "",
    flagLoadingProducts: false,
    flagDownloadingProducts: false,
    productsHeaders: [
      {text: "Barcode", value: "product_id"},
      {text: "Created at", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    breadcrumbs: [
      {text: "Incomplete products", disabled: true}
    ],
    products: [],
    fks: {},
    endpoints: {
      export: 'vrvm/incomplete-products/export'
    },
    exportEndpoint: '',
  }),
  mounted() {
    this.getProducts();
    this.exportEndpoint = `${process.env.VUE_APP_BASE_API}${encodeURI(this.endpoints.export)}`;
  },
  methods: {
    exportProducts() {
      this.flagDownloadingProducts = true

      axios
          .get(this.exportEndpoint, {responseType: 'blob'})
          .then((res) => {
            this.downloadFile([res.data])
          })
          .catch((error) => {
            console.log(error)
            this.flagDownloadingProducts = false
          });
    },
    downloadFile(data) {
      const url = window.URL.createObjectURL(new Blob(data))
      const tempLink = document.createElement("a")
      tempLink.href = url
      const filename = `IE - Incomplete Products (${this.getCurrentTime()}).xlsx`
      tempLink.setAttribute('download', filename)
      document.body.appendChild(tempLink)
      tempLink.click()
      tempLink.remove()
      this.flagDownloadingProducts = false
    },
    getCurrentTime() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    editProduct(index) {
      let currProduct = this.products[index];

      this.$router.push({
        name: 'FixIncompleteProduct',
        params: {
          fks: this.fks,
          product: currProduct,
        },
      })
    },
    getProducts() {
      this.flagLoadingProducts = true;
      let url = `${process.env.VUE_APP_BASE_API}vrvm/incomplete-products/get`;

      axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwt}`
            }
          })
          .then((response) => {
            this.flagLoadingProducts = false;

            this.products = response.data.products;
            this.fks = response.data.fks;
          })
          .catch(() => {
            this.flagLoadingProducts = false;
          });
    },
  }
});
</script>
